import { Typography, Button, Box, Paper, IconButton } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '@fontsource-variable/exo';


const ResultsSection = ({ onSubmission, result }) => {

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmission(false, '');
  };

  console.log(result)

  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mb: 2
      }}
    >
      <Typography sx={{fontFamily: 'Exo Variable', fontWeight: 700, fontSize: {xs: 32, sm: 48}, textAlign:'center', lineHeight: 1.3, mt: 4}}>Your feedback</Typography>
      <Box sx={{ alignSelf: 'flex-start' }}>
        <Button startIcon={<ArrowBackIcon />} variant="text" onClick={handleSubmit} size="small" sx={{my: 4, px: 2, fontSize: '14px'}}>Go back</Button>
      </Box>
      {
        result.map((section, index) => (
          <Paper key={index} sx={{ p: 4, borderRadius: '12px', mb: 4, width: '100%', position: 'relative' }}>
            {index === result.length - 1 && (
              <IconButton
                sx={{ position: 'absolute', top: '24px', right: '24px' }} 
                onClick={() => navigator.clipboard.writeText(Array.isArray(section.sectionContent) ? section.sectionContent.join('\n') : section.sectionContent)}
              >
                <ContentCopyRoundedIcon fontSize='large' />
              </IconButton>
            )}
            <Typography variant="h6" sx={{fontFamily: 'Exo Variable', fontWeight: 700, mb: 2}}>
              {section.sectionTitle}
            </Typography>
            <Typography sx={{fontFamily: 'Exo Variable', fontWeight: 400, lineHeight: 1.7, mb: 1}}>
              {
                Array.isArray(section.sectionContent) ? 
                  section.sectionContent.map((item, index) => (
                    <Typography key={index} sx={{fontFamily: 'Exo Variable', fontWeight: 400, lineHeight: 1.7, mb: 2}}>{item}</Typography>
                  )) :
                  section.sectionContent
              }
            </Typography>
          </Paper>
        ))
      }
    </Box>
    <Paper elevation={0} sx={{ p: 4, borderRadius: '12px', width: '100%', backgroundColor: '#e4e9ff', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    <Typography variant="h6" sx={{fontFamily: 'Exo Variable', fontWeight: 400, fontSize: '24px', lineHeight: '32px' }}>
      Be the first to access<br></br> more screening tools.
    </Typography>
    <Button sx={{backgroundColor: 'black', color: 'white' }} onClick={() => { window.location.href = "https://www.hireyesterday.com/"; }}>
      Join the waitlist
    </Button>
  </Paper>
  </>
  );
};

export default ResultsSection;