import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Paper, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import '@fontsource-variable/exo';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const SubmissionForm = ({ onSubmission }) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("We're evaluating your job description. It may take up to 20 seconds.");
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/completion`, { text: inputValue });
      console.log(response.data)
      onSubmission(true, response.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data.error) {
        console.log(err.response.data.message);
        setErrorMessage('There was an error processing your request: ' + err.response.data.message);
      } else {
        console.log('An error occurred', err);
        setErrorMessage('There was an error processing your request');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 'sm',
        mb: 6
      }}
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography sx={{fontFamily: 'Exo Variable', fontWeight: 700, fontSize: {xs: 32, sm: 48}, textAlign:'center', lineHeight: 1.3, mt: 4}}>Attracting the <span style={{backgroundColor: "rgba(0, 255, 194, 0.2)"}}>right candidate</span> starts here</Typography>
      <Typography sx={{fontFamily: 'Exo Variable', fontWeight: 400, fontSize: {xs: 14, sm: 18}, textAlign:'center', lineHeight: 1.3, mt: 4}}>A well-crafted job description is the cornerstone of every successful hiring process.</Typography>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          p: '24px',
          mt: 8,
          borderRadius: '24px',
          width: '100%',
          boxShadow: '0px 24px 48px -12px rgba(85, 105, 135, 0.1)'
        }}
      >
        <CssTextField
          variant="outlined" 
          multiline
          rows={8}
          placeholder="Paste your job description here."
          value={inputValue} 
          onChange={(e) => setInputValue(e.target.value)}
          sx={{borderColor: 'red'}}
          InputProps={{ sx: { borderRadius: '8px', fontSize: '14px'} }}
        />
        <LoadingButton loading={loading} variant="text" type="submit" size="large" sx={{mt: 3, backgroundColor: '#00FFC2', '&:hover': {backgroundColor: 'rgba(0, 255, 194, 0.2)',}}}>Evaluate</LoadingButton>
      </Paper>
      <Box
        sx={{
          mt: 4,
        }}
      >
        {errorMessage}
      </Box>
    </Box>
  );
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D2D2D2',
    },
  },
});

export default SubmissionForm;