import React, { useState } from 'react';
import { ThemeProvider, Container, CssBaseline } from '@mui/material';
import { theme } from './theme';
import styles from './styles.module.css';
import SubmissionForm from './components/SubmissionForm';
import HeroText from './components/HeroText';
import Navbar from './components/Navbar';
import ResultsSection from './components/ResultsSection';
import Footer from './components/Footer';
import { CSSTransition, SwitchTransition } from "react-transition-group";

const App = () => {
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState('');


  const handleSubmission = (submitStatus, result) => {
    setSubmitted(submitStatus);
    setResult(result);
  };
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HeroText />
        <SwitchTransition>
          <CSSTransition
            timeout={700}
            key={submitted}
            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
            classNames={{
              enter: submitted ? styles.fadeDownEnter : styles.fadeUpEnter,
              enterActive: submitted ? styles.fadeDownEnterActive : styles.fadeUpEnterActive,
              exit: submitted ? styles.fadeDownExit : styles.fadeUpExit,
              exitActive: submitted ? styles.fadeDownExitActive : styles.fadeUpExitActive,
            }}
          >
            {submitted ? <ResultsSection onSubmission={handleSubmission} result={result} /> : <SubmissionForm onSubmission={handleSubmission} />}
          </CSSTransition>
        </SwitchTransition>
        <Footer submitted={submitted}/>
      </Container>
    </ThemeProvider>
  );
};

export default App;
