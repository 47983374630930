import { Box, Button, Grid, Drawer, useMediaQuery, useTheme, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const logoSrc = "https://uploads-ssl.webflow.com/646b29cd554ed0cfa250aefe/64a3d4aca59722730d72884e_hireyesterday_left_dark.svg"

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  };

  const MobileNavbar = (
    <>
    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', }}>
        <a href="https://www.hireyesterday.com/">
        <img
        src={logoSrc}
        height={75}
        style={{ cursor: 'pointer' }}
        alt="Logo"
        />
        </a>
    </Box>
    <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', p: 2, gap: 2 }}
      >
        <Button variant="text" size="large" sx={{ backgroundColor: '#00FFC2', '&:hover': {backgroundColor: 'rgba(0, 255, 194, 0.2)',} }}
         onClick={() => { window.location.href = "https://www.hireyesterday.com/"; }}>
          Join waitlist
        </Button>
      </Box>
    </Drawer>
    </>
  );

  return isMobile ? (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ position: 'absolute', top: 24, left: 20 }}
      >
        <MenuIcon />
      </IconButton>
      {MobileNavbar}
    </>
  ) : (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', px: '48px', py: '14px' }}>
      <a style={{ paddingTop: '16px' }} href="https://www.hireyesterday.com/">
        <img
          src={logoSrc}
          width={250}
          style={{ cursor: 'pointer' }}
          alt="Logo"
        />
      </a>
          <Button variant="text" size="large" sx={{ backgroundColor: '#00FFC2', '&:hover': {backgroundColor: 'rgba(0, 255, 194, 0.2)',}, position: 'absolute', right: '80px' }}
           onClick={() => { window.location.href = "https://www.hireyesterday.com/"; }}>
            Join waitlist
          </Button>
    </Box>
  );
};

export default Navbar;
