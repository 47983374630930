import { createTheme } from '@mui/material/styles';
import '@fontsource/oxygen/300.css';
import '@fontsource/oxygen/400.css';
import '@fontsource/oxygen/700.css';

export const theme = createTheme({
  typography: {
    fontFamily: 'Oxygen, Arial, sans-serif',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#2A3342',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#2A3342',
          fontSize: '12px',
          fontWeight: 700,
          letterSpacing: '2px',
          lineHeight: '20px',
          borderRadius: '12px',
          padding: '12px 25px',
        },
      },
    },
  },
});
