import { Typography, Button, Box, Paper, Divider, Grid, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '@fontsource-variable/exo';
import { useState, useEffect } from 'react';


const Footer = ( {submitted} ) => {

  return (
    <>
      <Divider sx={{my: '40px', backgroundColor: 'rgb(228, 235, 243)'}} flexItem />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: '40px' }}>
        <Typography sx={{ width: "100%", fontFamily: 'Exo Variable', fontSize: '14px', fontWeight: 400, color: 'rgb(26, 26, 26)'}}>© 2023 Hire Yesterday. All rights reserved</Typography>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
          <Link href="https://www.hireyesterday.com/terms" sx={{fontFamily: 'Exo Variable', fontSize: '14px', fontWeight: 400, color: 'rgb(26, 26, 26)'}}>Terms of Use</Link>
          </Grid>
          <Grid item>
          <Link href="https://www.hireyesterday.com/privacy-policy" sx={{fontFamily: 'Exo Variable', fontSize: '14px', fontWeight: 400, color: 'rgb(26, 26, 26)'}}>Privacy Policy</Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;