import { Typography, Chip } from '@mui/material';
import '@fontsource-variable/exo';

const HeroText = () => {

  return (
    <>
    <Chip label="JOB DESCRIPTION OPTIMISER" sx={{backgroundColor: '#00FFC2', fontSize: 12, fontWeight: 500, mt: 6}} />
    </>
  );
};

export default HeroText;